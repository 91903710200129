<template>
    <div class="rohui_industry_right_content_model">
        <div class="rohui_industry_right_content">
            <div class="rohui_industry_title_model">
                <div class="rohui_industry_title">
                    <div class="rohui_title_small"></div>
                    <span>园区企业</span>
                </div>
                <div class="rohui_title_icon">
                    <img src="@/assets/images/rohui_indu_title_icon.png" alt="">
                </div>
            </div>
            <div class="rohui_company_list">
                <div class="rohui_company_items">
                    <!-- <div class="floor_number_box">{{ item.company_name }}</div> -->
                    <ul class="floor_company_all_list">
                        <li v-for="(item,index) in companyData" :key="index" @click="changeCompanyItem(item)"
                            :class="{ 'change_companyList_style' : item.company_name == $store.state.companyName }">
                            <img :src="require(`../../../assets/images/houseIcon${item.company_name == $store.state.companyName?'2':''}.png`)"
                                alt="">
                            {{ item.company_name }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import '@/assets/css/rohui/industryRight.less'
import { getCompany } from '@/api/rongHui.js'
export default {
    data() {
        return {
            companyData: []
        }
    },
    mounted() {
        getCompany({
            type: 2,
            limit: 999
        }).then(data => {
            console.log('融汇企业数据', data)
            data.data.map((item) => {
                const company_industry = [];
                item.name = item.company_name
                item.company_info = item.company_info
                item.company_keywords = item.company_keywords ? JSON.parse(item.company_keywords) : [];
                item.patent_star = item.patent_star ? JSON.parse(item.patent_star) : null;
                item.intellectual_property_statistics = item.intellectual_property_statistics ? JSON.parse(item.intellectual_property_statistics) : null
                item.rd_efficiency = item.rd_efficiency ? JSON.parse(item.rd_efficiency) : null
                item.distribution = item.distribution ? JSON.parse(item.distribution) : []
                item.company_industry = item.company_industry ? JSON.parse(item.company_industry) : []
                item.company_industry.map((item) => {
                    if (item.children && item.children.length > 0) {
                        item.children.map((jtem) => {
                            company_industry.push({
                                value: jtem.industry_id,
                                label: jtem.industry_name
                            })
                        })
                    }
                })
                item.company_industry = company_industry
                item.grade = item.grade ? JSON.parse(item.grade) : []
                item.tech_rd_strength = item.tech_rd_strength ? JSON.parse(item.tech_rd_strength) : []
            })
            this.companyData = data.data
        })
    },
    methods: {
        changeCompanyItem(item) {
            console.log(item)
            this.$store.dispatch('changeCompany', item)
        },
        replaceStr(str, index, char) {
            return str.substring(0, index) + char + str.substring(index + 1);
        }
    }
}
</script>