<template>
    <div class="rohui_industry_left_content_model">
        <div class="rohui_industry_left_content">
            <!-- 产业介绍 -->
            <div class="rohui_industry_intro">
                <div class="rohui_industry_title_model">
                    <div class="rohui_industry_title">
                        <div class="rohui_title_small"></div>
                        <span>产业介绍</span>
                    </div>
                    <div class="rohui_title_icon">
                        <img src="@/assets/images/rohui_indu_title_icon.png" alt="">
                    </div>
                </div>
                <div class="rohui_intro_text">
                    <h2>融汇国际大厦</h2>
                    <p class="rohui_intro_two">政府特定的5A级金融科技示范办公楼宇</p>
                    <p class="rohui_content_text">
                        坐落金融科技创新中心科新区“一线”高粱桥斜街的中心地带，给予进驻企业以政府扶持和优惠政策，形成，政策引导、政府扶持、企业创新的金融科技新高地。
                    </p>
                </div>
            </div>

            <!-- 产业扶持对象 -->
            <div class="rohui_suppot_object">
                <div class="rohui_industry_title_model">
                    <div class="rohui_industry_title">
                        <div class="rohui_title_small"></div>
                        <span>产业扶持对象</span>
                    </div>
                    <div class="rohui_title_icon">
                        <img src="@/assets/images/rohui_indu_title_icon.png" alt="">
                    </div>
                </div>
                <div class="rohui_industry_suppot_content_model">
                    <ul class="rohui_industry_suppot_content">
                        <li class="suppot_content_all_item suppot_content_item1">
                            <div class="suppot_content_item_title">
                                <img src="@/assets/images/suppot_con_icon1.png" alt="">
                                <h3>技术企业</h3>
                            </div>
                            <p class="suppot_content_text">
                                从事金融领域人工智能、大数据、互联网技术、分布式技术、安全技术等前沿技术研发和应用的企业。
                            </p>
                        </li>
                        <li class="suppot_content_all_item suppot_content_item2">
                            <div class="suppot_content_item_title">
                                <img src="@/assets/images/suppot_con_icon2.png" alt="">
                                <h3>行业研究机构</h3>
                            </div>
                            <p class="suppot_content_text">
                                挖掘行业发展潜力，为金融领域提供技术创新，能够创造新的模式、业务流程和产品、对产业做出实时分析与定位的研究机构。
                            </p>
                        </li>
                        <li class="suppot_content_all_item suppot_content_item3">
                            <div class="suppot_content_item_title">
                                <img src="@/assets/images/suppot_con_icon3.png" alt="">
                                <h3>专业服务机构</h3>
                            </div>
                            <p class="suppot_content_text">
                                为金融机构和金融科技企业提供有效信息、法律、财务、知识产权、人力资源、创业孵化等专业服务的机构。
                            </p>
                        </li>
                        <li class="suppot_content_all_item suppot_content_item4">
                            <div class="suppot_content_item_title">
                                <img src="@/assets/images/suppot_con_icon4.png" alt="">
                                <h3>行业组织</h3>
                            </div>
                            <p class="suppot_content_text">
                                不局限于传统、直接提供金融服务的组织机构。创新型搭建平台、监管或支持行业健康发展的组织机构。
                            </p>
                        </li>
                    </ul>
                    <div class="suppot_center_name">
                        支持对象
                    </div>
                </div>
            </div>

            <!-- 产业扶持政策 -->
            <div class="rohui_suppot_policy">
                <div class="rohui_industry_title_model">
                    <div class="rohui_industry_title">
                        <div class="rohui_title_small"></div>
                        <span>产业扶持政策</span>
                    </div>
                    <div class="rohui_title_icon">
                        <img src="@/assets/images/rohui_indu_title_icon.png" alt="">
                    </div>
                </div>
                <div class="rohui_policy_content_model">
                    <ul class="rohui_policy_content">
                        <li>
                            <div class="policy_content_icon">
                                <img src="@/assets/images/suppot_policy_icon1.png" alt="">
                            </div>
                            <div class="policy_content_text">
                                <span>空间聚集</span> — 吸引金融科技企业区域聚集
                            </div>
                        </li>
                        <li>
                            <div class="policy_content_icon">
                                <img src="@/assets/images/suppot_policy_icon2.png" alt="">
                            </div>
                            <div class="policy_content_text">
                                <span>行业发展</span> — 鼓励金融科技场景示范应用
                            </div>
                        </li>
                        <li>
                            <div class="policy_content_icon">
                                <img src="@/assets/images/suppot_policy_icon3.png" alt="">
                            </div>
                            <div class="policy_content_text">
                                <span>企业成长</span> — 支持企业设立实体或获得业务资格
                            </div>
                        </li>
                        <li>
                            <div class="policy_content_icon">
                                <img src="@/assets/images/suppot_policy_icon4.png" alt="">
                            </div>
                            <div class="policy_content_text">
                                <span>创新引领</span> — 完善人才培养体系任务资格
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import '@/assets/css/rohui/industryLeft.less'
    export default {

    }
</script>