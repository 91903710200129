<template>
    <div class="industry_modal_box">
        <div class="modal_off_model" @click="offModal">
            <img src="@/assets/images/company_off_icon.png" alt="">
        </div>
        <div class="company_content_model">
            <h2>{{ $store.state.companyContent.company_name }}</h2>
            <!-- <p class="modal_position">{{ $store.state.companyContent.position || '无' }}</p> -->
            <p class="industry_modal_content">{{ $store.state.companyContent.company_info }}</p>
        </div>
        <div class="IndustryModal-content">
            <div class="IndustryModal-left">
                <div class="IndustryModal-left-cont">
                    <div class="IndustryModal_title_model">
                        <div class="IndustryModal_title">
                            <div class="IndustryModal_title_small"></div>
                            <span>行业</span>
                        </div>
                        <div class="IndustryModal_title_icon">
                            <img src="@/assets/images/rohui_indu_title_icon2.png" alt="">
                        </div>
                    </div>
                    <template v-if="$store.state.companyContent.company_industry.length>0">
                        <div class="left_txt_title">
                            <p class="left_txt_title_p">行业类型：
                                <el-select v-model="typeValue" placeholder="请选择" size="small"
                                    :popper-append-to-body="false">
                                    <el-option v-for="item in $store.state.companyContent.company_industry"
                                        :key="item.value" :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </p>
                        </div>
                        <div class="left_cont_middle">
                            <div class="left_cont_middle_left">
                                <el-radio-group v-model="radioValue" size="mini" fill="#EA8937">
                                    <el-radio-button label="comprehensive">综合实力</el-radio-button>
                                    <el-radio-button label="independent">自主研发</el-radio-button>
                                </el-radio-group>
                            </div>
                            <div class="left_cont_middle_right">
                                <p style="font-size:18px;color:#E93A46">
                                    {{grade($store.state.companyContent.grade)&&grade($store.state.companyContent.grade)[radioValue].grade}}
                                </p>
                                <div class="left_cont_middle_right_bottom">
                                    <p>(超出同行<span>{{grade($store.state.companyContent.grade)&&(grade($store.state.companyContent.grade)[radioValue].proportion*100).toFixed(2)}}%</span>)
                                    </p>
                                </div>
                            </div>
                        </div>
                        <RoHuiIndustryRight :strengthData="strength($store.state.companyContent.tech_rd_strength)" />
                    </template>
                    <div v-else class="left_nodata">
                        <img src="@/assets/images/word_cloud_nodata_rh.png" alt="" />
                    </div>
                </div>
                <div class="IndustryModal-left-middle">
                    <div class="IndustryModal_title_model">
                        <div class="IndustryModal_title">
                            <div class="IndustryModal_title_small"></div>
                            <span>技术质量</span>
                        </div>
                        <div class="IndustryModal_title_icon">
                            <img src="@/assets/images/rohui_indu_title_icon2.png" alt="">
                        </div>
                    </div>
                    <div class="left_txt_other"
                        v-if="$store.state.companyContent.patent_star&&$store.state.companyContent.patent_star.length>0">
                        <p style="font-size:14px;">发明专利：<span
                                style="font-size:24px;color:#EA8937;">{{$store.state.companyContent.intellectual_property_statistics.independent_rd_invention_patent_num}}</span>
                            件，占比
                            <span
                                style="font-size:24px;color:#EA8937;">{{$store.state.companyContent.intellectual_property_statistics.independent_rd_invention_patent_num?($store.state.companyContent.intellectual_property_statistics.independent_rd_invention_patent_num/$store.state.companyContent.intellectual_property_statistics.invention_patent_num*100).toFixed(2):0}}</span>%，
                            软著：<span
                                style="font-size:24px;color:#EA8937;">{{$store.state.companyContent.intellectual_property_statistics.software_copyright_num}}</span>
                            项
                        </p>
                        <p>实用新型：<span
                                style="font-size:24px;color:#EA8937;">{{$store.state.companyContent.intellectual_property_statistics.independent_rd_utility_model_patent_num}}</span>
                            件，占比
                            <span
                                style="font-size:24px;color:#EA8937;">{{$store.state.companyContent.intellectual_property_statistics.independent_rd_utility_model_patent_num?($store.state.companyContent.intellectual_property_statistics.independent_rd_utility_model_patent_num/$store.state.companyContent.intellectual_property_statistics.utility_model_patent_num*100).toFixed(2):0}}</span>%
                        </p>
                        <div class="PieChat1">
                            <div id="myChart1" :style="{width: '350px', height: '160px'}"> </div>
                            <div class="PieChat1-item">
                                <div class="PieChat1-wrag">
                                    <p><span
                                            style="font-size:14px;color:#EA8937;">{{$store.state.companyContent.patent_star[5].patent_num}}</span>
                                        件，占比 <span
                                            style="font-size:14px;color:#EA8937;">{{($store.state.companyContent.patent_star[5].patent_num_proportion*100).toFixed(2)}}%</span>
                                    </p>
                                </div>
                                <div class="PieChat1-wrag">
                                    <p><span
                                            style="font-size:14px;color:#EA8937;">{{$store.state.companyContent.patent_star[4].patent_num}}</span>
                                        件，占比 <span
                                            style="font-size:14px;color:#EA8937;">{{($store.state.companyContent.patent_star[4].patent_num_proportion*100).toFixed(2)}}%</span>
                                    </p>
                                </div>
                                <div class="PieChat1-wrag">
                                    <p><span
                                            style="font-size:14px;color:#EA8937;">{{$store.state.companyContent.patent_star[3].patent_num}}</span>
                                        件，占比 <span
                                            style="font-size:14px;color:#EA8937;">{{($store.state.companyContent.patent_star[3].patent_num_proportion*100).toFixed(2)}}%</span>
                                    </p>
                                </div>
                                <div class="PieChat1-wrag">
                                    <p><span
                                            style="font-size:14px;color:#EA8937;">{{$store.state.companyContent.patent_star[2].patent_num}}</span>
                                        件，占比 <span
                                            style="font-size:14px;color:#EA8937;">{{($store.state.companyContent.patent_star[2].patent_num_proportion*100).toFixed(2)}}%</span>
                                    </p>
                                </div>
                                <div class="PieChat1-wrag">
                                    <p><span
                                            style="font-size:14px;color:#EA8937;">{{$store.state.companyContent.patent_star[1].patent_num}}</span>
                                        件，占比 <span
                                            style="font-size:14px;color:#EA8937;">{{($store.state.companyContent.patent_star[1].patent_num_proportion*100).toFixed(2)}}%</span>
                                    </p>
                                </div>
                            </div>
                            <div class="PieChat1-img">
                                <img src="../../../assets/images/ring3.png" />
                            </div>
                        </div>
                    </div>
                    <div v-else class="left_txt_other_nodata">
                        <img src="@/assets/images/word_cloud_nodata_rh.png" alt="" />
                    </div>
                </div>
            </div>
            <div class="IndustryModal-right">
                <div class="IndustryModal-right-footer">
                    <div class="IndustryModal_title_model">
                        <div class="IndustryModal_title">
                            <div class="IndustryModal_title_small"></div>
                            <span>主要技术</span>
                        </div>
                        <div class="IndustryModal_title_icon">
                            <img src="@/assets/images/rohui_indu_title_icon2.png" alt="">
                        </div>
                    </div>
                    <WordCloud noDataIcon="rh" :wordCloudData="[]" />
                </div>
                <div class="IndustryModal-right-chart">
                    <div class="IndustryModal_title_model">
                        <div class="IndustryModal_title">
                            <div class="IndustryModal_title_small"></div>
                            <span>研发效率</span>
                        </div>
                        <div class="IndustryModal_title_icon">
                            <img src="@/assets/images/rohui_indu_title_icon2.png" alt="">
                        </div>
                    </div>
                    <template
                        v-if="$store.state.companyContent.distribution&&$store.state.companyContent.distribution.length > 0">
                        <div class="IndustryModal_chart_text">
                            <p style="font-size:14px;">该企业近三年的研发效率平均值<span
                                    style="font-size:14px;color:#FD6B44;">({{$store.state.companyContent.rd_efficiency?($store.state.companyContent.rd_efficiency.recent_years_company_rd_efficiency_avg).toFixed(2):0}})</span>
                            </p>
                            <p style="font-size:14px;">
                                {{$store.state.companyContent.rd_efficiency&&($store.state.companyContent.rd_efficiency.recent_years_company_rd_efficiency_avg*100)
                                > ($store.state.companyContent.rd_efficiency.recent_years_cluster_rd_efficiency_avg*100)
                                ?
                                '高于':($store.state.companyContent.rd_efficiency.recent_years_company_rd_efficiency_avg*100)
                                ==
                                ($store.state.companyContent.rd_efficiency.recent_years_cluster_rd_efficiency_avg*100)?'等于':($store.state.companyContent.rd_efficiency.recent_years_company_rd_efficiency_avg*100)
                                < ($store.state.companyContent.rd_efficiency.recent_years_cluster_rd_efficiency_avg*100)?'低于':''}}同行业研发效率平均值<span
                                    style="font-size:14px;color:#FDA14B;">
                                    ({{$store.state.companyContent.rd_efficiency?($store.state.companyContent.rd_efficiency.recent_years_cluster_rd_efficiency_avg).toFixed(2):0}})</span>
                            </p>
                        </div>
                        <div class="IndustryModal-PieChat2">
                            <div id="myChart2" :style="{width: '450px', height: '290px'}"> </div>
                        </div>
                    </template>
                    <div v-else class="IndustryModal_chart_text_nodata">
                        <img src="@/assets/images/word_cloud_nodata_rh.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import RoHuiIndustryRight from '@/components/roHui/roHuiIndustry/RoHuiIndustryRight.vue';
import WordCloud from '@/components/WordCloud.vue';
export default {
    data() {
        return {
            radioValue: 'comprehensive',
            typeValue: '',
        }
    },
    components: {
        RoHuiIndustryRight,
        WordCloud,
    },
    computed: {
        grade() {
            const gradeName = {
                0: 'A',
                1: 'B',
                2: 'C',
                3: 'D',
                4: 'E'
            }
            return function (val) {
                return val.find((value) => {
                    value.independent = {
                        grade: gradeName[value.independent_rd_grade],
                        proportion: value.independent_rd_exceed_proportion
                    }
                    value.comprehensive = {
                        grade: gradeName[value.comprehensive_strength_grade],
                        proportion: value.comprehensive_strength_exceed_proportion
                    }
                    return this.typeValue == value.industry_id
                })
            }
        },
        strength() {
            return function (val) {
                return val.find((value) => {
                    return this.typeValue == value.industry_id
                })
            }
        }
    },
    methods: {
        offModal() {
            this.$store.dispatch('offModal')
        },
        drawLine() {
            if (!this.$store.state.companyContent.patent_star || this.$store.state.companyContent.patent_star == 0) return
            let myChart1 = this.$echarts.init(document.getElementById('myChart1'))
            let infoData = [
                { value: this.$store.state.companyContent.patent_star[5].patent_num, name: '5星专利' },
                { value: this.$store.state.companyContent.patent_star[4].patent_num, name: '4星专利' },
                { value: this.$store.state.companyContent.patent_star[3].patent_num, name: '3星专利' },
                { value: this.$store.state.companyContent.patent_star[2].patent_num, name: '2星专利' },
                { value: this.$store.state.companyContent.patent_star[1].patent_num, name: '1星专利' },
            ]
            let option = {
                tooltip: {
                    trigger: 'item',
                    confine: true
                },
                title: {
                    text: '{b|技术评级}',
                    textStyle: {
                        rich: {
                            b: {
                                fontSize: "16",
                                color: "#fff",
                            }
                        }
                    },
                    itemGap: 10,
                    left: 'center',
                    top: "center"
                },
                legend: {
                    orient: 'vertical',
                    x: '240',
                    y: '20',
                    align: 'left',
                    itemHeight: 10,
                    itemWidth: 10,
                    itemGap: 10,
                    textStyle: {
                        color: '#fff',
                        fontSize: '14',
                    },
                },
                color: ['#FDCF4C', '#FD6B44', '#EFF4FF', '#FED99D', '#FDA14B'],
                series: [{
                    name: 'Access From',
                    type: 'pie',
                    radius: ['80%', '65%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'outer',
                        alignTo: 'labelLine',
                    },
                    emphasis: {
                        label: {
                            show: false,
                            fontSize: '40',
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    itemStyle: {
                        borderRadius: 0,
                    },
                    data: infoData,
                }],
            }
            myChart1.setOption(option)
        },
        drawLine2() {
            if (!this.$store.state.companyContent.distribution || this.$store.state.companyContent.distribution.length == 0) return
            let datetime = [], company = [], industry = [];
            this.$store.state.companyContent.distribution.map((item) => {
                datetime.push(String(item.date_time))
                company.push(item.company_rd_efficiency)
                industry.push(item.cluster_rd_efficiency)
            })
            let myChart2 = this.$echarts.init(document.getElementById('myChart2'))
            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                    }
                },
                color: ['#EA8937', '#FC4F2D'],
                legend: {
                    itemHeight: 6,
                    itemWidth: 6,
                    x: 'center',
                    y: 'top',
                    textStyle: {
                        fontSize: 14,
                        color: '#ffffff'
                    },
                    data: [
                        { name: '企业自主研发', icon: 'rect' },
                        { name: '行业平均自主研发', icon: 'rect' }]
                },
                grid: {
                    left: '4%',
                    right: '13%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        axisTick: {
                            show: false
                        },
                        data: datetime,
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#ffffff'
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        boundaryGap: false,
                        nameTextStyle: {
                            color: "#ffffff",
                            fontSize: 12,
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed',
                                color: 'rgb(67,71,87)'
                            }
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#ffffff'
                            }
                        }
                    }
                ],
                series: [
                    {
                        name: '企业自主研发',
                        type: 'line',
                        showSymbol: false,
                        emphasis: {
                            focus: 'series'
                        },
                        data: company
                    },
                    {
                        name: '行业平均自主研发',
                        type: 'line',
                        showSymbol: false,
                        emphasis: {
                            focus: 'series'
                        },
                        data: industry
                    },
                ]
            };

            myChart2.setOption(option)
        },
        defaultSelect() {
            const opt = this.$store.state.companyContent.company_industry
            if (opt && opt.length > 0) {
                this.typeValue = opt[0].value
            }
        },
    },
    mounted() {
        this.defaultSelect()
        this.drawLine()
        this.drawLine2()
    }
}
</script>
<style lang="less">
.industry_modal_box {
    position: absolute;
    height: 860px;
    width: 865px;
    top: 50%;
    left: 50%;
    transform: translate(-49.5%, -50%);
    background: url('./../../../assets/images/company_modal.png') no-repeat;
    z-index: 9999;

    .modal_off_model {
        float: right;
        margin-top: 20px;
        margin-right: 14px;
        cursor: pointer
    }

    .company_content_model {
        padding: 40px 20px 0;

        h2 {
            font-size: 20px;
            text-align: center;
        }

        .industry_modal_content {
            text-indent: 2em;
            font-size: 14px;
            margin-top: 10px;
            text-align: center;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .modal_position {
            font-size: 14px;
            text-align: center;
            // margin: 12px 0;
            padding: 10px 10px 0px 0px;
            display: block;
        }
    }

    .IndustryModal-content {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;

        .IndustryModal-left {
            margin-left: 20px;

            .IndustryModal-left-cont {
                .IndustryModal_title_model {
                    .IndustryModal_title {
                        display: flex;
                        align-items: center;
                        font-size: 18px;
                        font-weight: 500;

                        .IndustryModal_title_small {
                            height: 4px;
                            width: 4px;
                            background: #fcebbf;
                            margin-right: 10px;
                        }
                    }

                    .IndustryModal_title_icon {
                        height: 4px;
                        width: 100%;
                        display: flex;

                        .IndustryModal_title_icon img {
                            width: 100%;
                        }
                    }
                }

            }

            .left_txt_title>.left_txt_title_p {
                margin-top: 15px;
                margin-left: 15px;

                .el-select-dropdown__empty {
                    padding: 15px 20px;
                }

                .el-select .el-input__inner:focus,
                .el-range-editor.is-active,
                .el-range-editor.is-active:hover,
                .el-select .el-input.is-focus .el-input__inner {
                    border-color: #ea8937;
                }

                .el-select .el-input .el-select__caret {
                    color: #ffffff;
                    font-weight: bold;
                }

                .el-select-dropdown {
                    background-color: #1a120c;
                    border: 1px solid #ea8937;
                }

                .popper__arrow::after {
                    border-bottom-color: #ea8937 !important
                }

                .el-select-dropdown__item.selected {
                    color: #ffffff;
                }

                .el-select-dropdown__item {
                    color: #fed99d;
                    font-weight: bold;
                }

                .el-select-dropdown__item.hover,
                .el-select-dropdown__item:hover {
                    background-color: #ea8937
                }
            }

            .left_cont_middle {
                display: flex;
                justify-content: space-between;
                margin: 0 20px;
                margin-top: 18px;

                .left_cont_middle_left {
                    display: flex;
                    align-items: center;
                    height: 70px;

                    .el-radio-button__inner {
                        background: transparent;
                        color: #FFFFFF;
                    }
                }

                .left_cont_middle_right {
                    text-align: center;

                    .left_cont_middle_right_bottom {
                        width: 130px;
                        height: 20px;
                        background: #EB515C;
                    }

                }
            }
        }

        .IndustryModal-left-middle {
            margin-top: 20px;

            .IndustryModal_title_model {
                .IndustryModal_title {
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    font-weight: 500;

                    .IndustryModal_title_small {
                        height: 4px;
                        width: 4px;
                        background: #fcebbf;
                        margin-right: 10px;
                    }
                }

                .IndustryModal_title_icon {
                    height: 4px;
                    width: 100%;
                    display: flex;

                    .IndustryModal_title_icon img {
                        width: 100%;
                    }
                }
            }

            .left_txt_other {
                margin-top: 20px;
                margin-left: 10px;

                span {
                    font-family: 'bigdsfont';
                }
            }

            .PieChat1 {
                // margin-left: -50px;
                display: flex;
                position: relative;
                margin-left: -100px;
                padding-bottom: 30px;

                .PieChat1-img {
                    position: absolute;
                    top: 30px;
                    left: 125px;
                }

                .PieChat1-item {
                    margin-top: 20px;
                    margin-left: -34px;
                }

                .PieChat1-wrag {
                    padding-bottom: 5px;
                    display: flex;
                }
            }
        }

        .IndustryModal-right {

            .IndustryModal-right-footer {
                .IndustryModal_title_model {
                    .IndustryModal_title {
                        display: flex;
                        align-items: center;
                        font-size: 18px;
                        font-weight: 500;

                        .IndustryModal_title_small {
                            height: 4px;
                            width: 4px;
                            background: #fcebbf;
                            margin-right: 10px;
                        }
                    }

                    .IndustryModal_title_icon {
                        height: 4px;
                        width: 100%;
                        display: flex;

                        .IndustryModal_title_icon img {
                            width: 10px;
                        }
                    }
                }
            }

            .IndustryModal-right-chart {
                .IndustryModal_title_model {
                    .IndustryModal_title {
                        display: flex;
                        align-items: center;
                        font-size: 18px;
                        font-weight: 500;

                        .IndustryModal_title_small {
                            height: 4px;
                            width: 4px;
                            background: #fcebbf;
                            margin-right: 10px;
                        }
                    }

                    .IndustryModal_title_icon {
                        height: 4px;
                        width: 100%;
                        display: flex;

                        .IndustryModal_title_icon img {
                            width: 10px;
                        }
                    }
                }

                .IndustryModal_chart_text {
                    margin-top: 17px;
                    margin-left: 5px;
                }

                .IndustryModal_chart_text_nodata {
                    width: 100%;
                    height: 420px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 160px;
                        height: 110px;
                    }
                }

                .IndustryModal-PieChat2 {
                    margin-top: 30px;
                }
            }

        }

    }
}

.left_nodata {
    width: 100%;
    height: 246px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 160px;
        height: 110px;
    }
}

.left_txt_other_nodata {
    width: 100%;
    height: 380px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 160px;
        height: 110px;
    }
}
</style>